import React from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";

import Home from "./pages/Home/Home";
import firebase from "./shared/firebase";
import UserContext from "./shared/UserContext";
import { theme } from "./theme";
import useUser from "./hooks/useUser";
import { useStyles } from "./App.styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import User from "./pages/User/User";
import Report from "./pages/Report/Report";
import SignIn from "./pages/SignIn/SignIn";

function App() {
  const classes = useStyles();
  const history = useHistory();

  const user = useUser();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      setIsDrawerOpen(false);
    });

    return () => {
      unlisten();
    };
  }, [setIsDrawerOpen, history]);

  const signOut = React.useCallback(() => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        history.push("/");
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [history]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="application-name" content="Turnips Exchange - Admin"></meta>
        <meta
          name="apple-mobile-web-app-title"
          content="Turnips Exchange - Admin"
        />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            autoHideDuration={2000}
          >
            <UserContext.Provider value={user}>
              <Box
                className={classes.root}
                display="flex"
                flexDirection="column"
              >
                <Box className={classes.bg}></Box>
                <AppBar position="sticky">
                  <Toolbar
                    style={{
                      color: "#6b5c43",
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box textAlign="center" position="relative" zIndex={2}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                          setIsDrawerOpen(true);
                        }}
                      >
                        <Icon className="fas fa-bars" />
                      </IconButton>
                    </Box>
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      zIndex={1}
                    >
                      <Box
                        component={Link}
                        // @ts-ignore
                        to="/"
                        fontSize={18}
                        fontWeight="700"
                        style={{
                          textDecoration: "none",
                          color: "rgb(107, 92, 67)",
                        }}
                      >
                        Turnips Exchange - Admin
                      </Box>
                    </Box>
                  </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={() => {
                    setIsDrawerOpen(false);
                  }}
                >
                  <Box
                    width={250}
                    height="100%"
                    bgcolor="#f1e26f"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        mt={4}
                      >
                        <Box
                          component="img"
                          // @ts-ignore
                          src="https://www.animal-crossing.com/new-horizons/assets/img/global/logos/logo-acnh-en-2x.png"
                          width="auto"
                          height={72}
                        ></Box>
                      </Box>
                      <Box
                        mt={2}
                        fontSize={18}
                        fontWeight="700"
                        textAlign="center"
                      >
                        Turnips Exchange - Admin
                      </Box>
                      <List>
                        <ListSubheader
                          style={{ color: "rgba(107, 92, 67, .5)" }}
                        >
                          Management
                        </ListSubheader>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/users"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon className="fas fa-store" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                          </Box>
                        </ListItem>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/reports"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon className="fas fa-plane" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                          </Box>
                        </ListItem>
                      </List>
                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {!user && (
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/sign-in"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            color="text.primary"
                            borderColor="text.primary"
                            borderBottom="2px solid"
                            fontWeight="700"
                            fontSize={16}
                            style={{ textDecoration: "none" }}
                          >
                            Sign In
                          </Box>
                        )}
                        {user && (
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            color="text.primary"
                            borderColor="text.primary"
                            borderBottom="2px solid"
                            fontWeight="700"
                            fontSize={16}
                            style={{ textDecoration: "none" }}
                            onClick={signOut}
                          >
                            Sign Out
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      my={2}
                    >
                      <Box>v0.3.0</Box>

                      <Box
                        component="a"
                        // @ts-ignore
                        href="https://twitter.com/TurnipsExchange"
                        _target="blank"
                        style={{ textDecoration: "underline" }}
                      >
                        @TurnipsExchange
                      </Box>
                      <Box>Click ↑ if any issue</Box>
                    </Box>
                  </Box>
                </Drawer>
                <Box
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  style={{ overflowY: "scroll" }}
                >
                  <Switch>
                    <Route path="/" exact>
                      <Home />
                    </Route>
                    <Route path="/users" exact>
                      <User />
                    </Route>
                    <Route path="/reports" exact>
                      <Report />
                    </Route>
                    <Route path="/sign-in" exact>
                      <SignIn />
                    </Route>
                  </Switch>
                </Box>
                {/* <Box flexShrink={0} className={classes.footer}>
                  <Box mx={4} fontSize={12}>
                    Some words here.
                  </Box>
                </Box> */}
              </Box>
            </UserContext.Provider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
