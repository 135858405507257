import React from "react";
import UserContext from "../shared/UserContext";

export function useRedirectToSignInIfNotAlready(pageName = "this page") {
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    if (user !== null) return;
    const redirectUrl = new URL("/sign-in", window.location.origin);
    redirectUrl.searchParams.set(
      "message",
      `Please sign in before viewing ${pageName}`
    );

    window.location.replace(redirectUrl.toString());
  }, [user, pageName]);

  return user;
}
