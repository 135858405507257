import React from "react";
import BasicProfileCard from "./components/BasicProfileCard";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import VerifiedUserCard from "./components/VerifiedProfileCard";
import { useUidSearch, SearchType } from "../../hooks/useUidSearch";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

export default function User() {
  const history = useHistory();
  const [radioValue, setRadioValue] = React.useState<number>(SearchType.EMAIL);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const handleRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(Number(event.target.value));
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const uidSearch = useUidSearch();

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const uid = url.searchParams.get("uid");
    if (uid) {
      uidSearch.search(SearchType.UID, uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    history.push({
      search: `?uid=${uidSearch.uid}`,
    });
  }, [history, uidSearch.uid]);

  const labels = Object.keys(SearchType).filter(
    (k) => typeof SearchType[k as any] === "number"
  );

  return (
    <Container maxWidth="md">
      <Card style={{ marginTop: 32 }}>
        <CardContent>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              onChange={handleRadioButton}
            >
              {labels.map((label) => {
                return (
                  <FormControlLabel
                    key={label}
                    value={SearchType[label as any]}
                    checked={radioValue === Number(SearchType[label as any])}
                    control={<Radio />}
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: 32 }} variant="outlined">
            <TextField label="Search" onChange={handleSearchChange} />
            <Button
              onClick={() => {
                uidSearch.search(radioValue, searchValue);
              }}
              disabled={!searchValue}
            >
              Search
            </Button>
          </FormControl>
        </CardContent>
      </Card>

      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        p={4}
        style={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {uidSearch.uid && [
          <BasicProfileCard
            uid={uidSearch.uid}
            key={"user-search" + uidSearch.uid}
          />,
          <VerifiedUserCard
            uid={uidSearch.uid}
            key={"verified-user-search" + uidSearch.uid}
          />,
        ]}
        {uidSearch.uid === null && (
          <Box mb={2}>
            <Alert severity="warning">Profile doesn't exist</Alert>
          </Box>
        )}
      </Box>
    </Container>
  );
}
