import firebase from "./shared/firebase";
import { VerificationLevel } from "./types/data";

export class UserApi {
  static initMyShortId() {
    return firebase.functions().httpsCallable("initMyShortId")();
  }

  static ip() {
    return firebase.functions().httpsCallable("ip")();
  }

  static async getIdByDisplayName(searchPhrase: string): Promise<string[]> {
    return firebase
      .firestore()
      .collection("users")
      .where("displayName", "==", searchPhrase)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => doc.id);
      });
  }
}

export class ReportApi {
  static ref() {
    return firebase.database().ref(`report`);
  }
}

export class FlightApi {
  static ref(flightCreatorId: string) {
    return firebase.database().ref(`flights`).child(flightCreatorId);
  }
  static refPrivate(flightCreatorId: string) {
    return firebase.database().ref(`flightPrivate`).child(flightCreatorId);
  }
}

export class ChatApi {
  static ref(flightCreatorId: string) {
    return firebase.database().ref(`flightChat`).child(flightCreatorId);
  }
}

export interface UpdateVerificationProfilePayload {
  displayNameVerified?: string;
  islandNameVerified?: string;
  verificationLevel?: VerificationLevel;
}

export class AdminApi {
  static confirmReport(reportedUid: string) {
    return firebase.functions().httpsCallable("confirmReportAdmin")({
      reportedUid,
    });
  }

  static dismissReport(reportedUid: string) {
    return firebase.functions().httpsCallable("dismissReportAdmin")({
      reportedUid,
    });
  }

  static endFlight(flightCreatorId: string) {
    return firebase.functions().httpsCallable("endFlightAdmin")({
      flightCreatorId,
    });
  }

  static updateVerificationProfile(
    uid: string | undefined,
    payload: UpdateVerificationProfilePayload
  ) {
    const { verificationLevel, ...rest } = payload;
    return firebase.functions().httpsCallable("updateVerificationProfileAdmin")(
      {
        ...rest,
        uid,
        verificationLevel: verificationLevel === null ? null : VerificationLevel.LEVEL_ONE,
      }
    );
  }

  static disableUser(uid: string) {
    return firebase.functions().httpsCallable("disableUserAdmin")({
      uid,
    });
  }

  static enableUser(uid: string) {
    return firebase.functions().httpsCallable("enableUserAdmin")({
      uid,
    });
  }

  static isUserDisabled(uid: string) {
    return firebase.functions().httpsCallable("getUserDisabled")({
      uid,
    });
  }

  static async getUidByEmail(email: string): Promise<string | undefined> {
    return firebase
      .functions()
      .httpsCallable("getUidByEmailAdmin")({
        email,
      })
      .then((res) => res.data || undefined);
  }

  static async getEmailByUid(uid: string): Promise<string | undefined> {
    return firebase
      .functions()
      .httpsCallable("getEmailByUidAdmin")({
        uid,
      })
      .then((res) => res.data || undefined);
  }

  static async getFlightPrivateCode(
    flightCreatorId: string
  ): Promise<string | undefined> {
    return firebase
      .functions()
      .httpsCallable("getFlightPrivateCodeAdmin")({
        flightCreatorId,
      })
      .then((res) => res.data || undefined);
  }
}
