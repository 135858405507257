import { ChatApi } from "./../api";
import React from "react";
import { Chat } from "../types/data";

export function useChatHistory(flightCreatorId: string, count: number = 200) {
  const [state, setState] = React.useState<Chat[]>([]);

  React.useEffect(() => {
    ChatApi.ref(flightCreatorId)
      .limitToLast(count)
      .once("value", (snapshot) => {
        setState(Object.values(snapshot.val() || {}));
      });
  });

  return state;
}
