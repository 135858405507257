import React from "react";
import { ReportApi } from "../api";
import { createSafeDate } from "../shared/utils";
import { Report } from "../types/data";

function mapReportedUser(value: any): Report[] {
  return Object.keys(value).map((key) => mapReport(key, value[key]));
}

function mapReport(key: string, value: any) {
  return {
    uid: key,
    reason: typeof value === "object" ? value.reason : undefined,
    createdAt: createSafeDate(
      typeof value === "object" ? value.createdAt : value
    ),
  };
}

export default function useReport() {
  const [state, setState] = React.useState<{ [k: string]: Report[] }>({});

  React.useEffect(() => {
    const ref = ReportApi.ref();
    const callback1 = ReportApi.ref().on("child_added", (snapshot) => {
      if (snapshot.val().dealtWith) {
        return;
      }
      setState((prev) =>
        prev
          ? {
              ...prev,
              [snapshot.key!]: mapReportedUser(snapshot.val()),
            }
          : {
              [snapshot.key!]: mapReportedUser(snapshot.val()),
            }
      );
    });

    const callback2 = ReportApi.ref().on("child_removed", (snapshot) => {
      setState((prev) => {
        const copy = { ...prev };
        delete copy[snapshot.key!];
        return copy;
      });
    });

    return () => {
      ref.off("child_added", callback1);
      ref.off("child_removed", callback2);
    };
  }, []);

  return state;
}
