import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f1e26f",
    },
    secondary: {
      main: "#13c2c2",
    },
    error: {
      main: "#f5222d",
    },
    warning: {
      main: "#fa8c16",
    },
    info: {
      light: "#98d2e3",
      main: "#2d6895",
    },
    success: {
      light: "#82d7aa",
      main: "#007d75",
    },
    text: {
      primary: "#6b5c43",
      secondary: "#13c2c2",
    },
  },
  typography: {
    fontFamily: '"Zilla Slab", sans-serif',
    h2: {
      fontSize: "calc(22 / var(--font-base) * 1rem)",
      lineHeight: "1.3",
      marginBottom: "0.6em",
    },
  },
  overrides: {
    MuiIcon: {
      root: {
        width: "unset",
      },
      fontSizeLarge: {
        width: "100%",
      },
    },
    MuiListItem: {
      root: {
        "& .MuiTypography-body1": {
          fontWeight: 600,
          fontSize: 16,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    // TODO: refactor, this change is done for SubmitPrice DatePicker
    MuiInputAdornment: {
      root: {
        "& .MuiButtonBase-root.MuiIconButton-root": {
          padding: 0,
        },
      },
    },
  },
});
