import { FlightApi } from "./../api";
import { Flight } from "./../types/data";
import React from "react";

export function useFlight(flightCreatorId: string) {
  const [state, setState] = React.useState<Flight | null | undefined>(
    undefined
  );

  React.useEffect(() => {
    const ref = FlightApi.ref(flightCreatorId);
    const callback = ref.on("value", (snapshot) => {
      setState(snapshot.val() || null);
    });

    return () => {
      ref.off("value", callback);
    };
  });

  return state;
}
