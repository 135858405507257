import { useSnackbar } from "notistack";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import firebase from "../../shared/firebase";
import UserContext from "../../shared/UserContext";
import { User } from "../../types/data";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// TODO: refactor, use mui input validation, there must be something like this
function useEmailField() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string | null | undefined>(null);
  const onChangeEmail = React.useCallback((e) => {
    const newValue = e.target.value;

    setEmail(newValue);

    if (newValue === undefined) {
      return;
    }
    if (validateEmail(newValue)) {
      setError(null);
    } else {
      setError("Invalid email.");
    }
  }, []);

  return {
    validated: email && error === null,
    error,
    email,
    onChangeEmail,
  };
}

function useSignInWithMagicLink(user: User) {
  const [submitting, setSubmitting] = React.useState(false);
  const [sent, setSent] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | null | undefined>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const signInWithMagicLink = React.useCallback((email: string) => {
    setError(undefined);
    setSubmitting(true);
    const verificationUrl = new URL("/sign-in", window.location.origin);
    verificationUrl.searchParams.set("message", "Verifying magic link...");
    firebase
      .auth()
      .sendSignInLinkToEmail(email, {
        url: verificationUrl.toString(),
        handleCodeInApp: true,
      })
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        setSent(email);
        setError(null);
        setSubmitting(false);
      })
      .catch(function (error) {
        console.error(error);
        setSent(undefined);
        setError(error.message);
        setSubmitting(false);
        // Some error occurred, you can inspect the code: error.code
      });
  }, []);

  React.useEffect(() => {
    if (user === undefined) return;

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setError(undefined);
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please confirm your email");
      }

      setSubmitting(true);
      firebase
        .auth()
        .signInWithEmailLink(email!, window.location.href)
        .then(function (result) {
          setError(null);
          window.localStorage.removeItem("emailForSignIn");
          enqueueSnackbar("Successful", {
            variant: "success",
          });
          setSubmitting(false);
          // TODO: should go back to original url
          history.push("/");
        })
        .catch(function (error) {
          console.error(error);
          setSent(undefined);
          setSubmitting(false);
          setError(error.message);
        });
    }
  }, [setSent, setError, enqueueSnackbar, history, user]);

  return {
    sent,
    error,
    signInWithMagicLink,
    submitting,
  };
}

function useHandleQueryMessage() {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const message = currentUrl.searchParams.get("message");
    if (message) {
      enqueueSnackbar(message, {
        variant: "info",
      });
    }
  }, [enqueueSnackbar]);
}

// Note: returnUrl should be relative
function getRedirectUrl() {
  const currentUrl = new URL(window.location.href);
  const returnUrl = currentUrl.searchParams.get("returnUrl");

  return returnUrl || "/passport";
}

const useStyles = makeStyles({
  root: {
    width: "80%",
    marginBottom: 16,
    flexShrink: 0,
    margin: "32px auto",
  },
  title: {
    fontSize: 14,
  },
});

export default function SignIn() {
  useHandleQueryMessage();
  const classes = useStyles();

  const user = React.useContext(UserContext);
  const { email, error, onChangeEmail, validated } = useEmailField();

  const {
    sent,
    error: magicLinkError,
    signInWithMagicLink,
    submitting,
  } = useSignInWithMagicLink(user);

  React.useEffect(() => {
    if (sent) {
      // TODO: refactor
      onChangeEmail({ target: { value: undefined } });
    }
  }, [sent, onChangeEmail]);

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (user && user.emailVerified) {
    return <Redirect to={getRedirectUrl()} />;
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>Sign In - Turnips Exchange - Admin</title>
      </Helmet>
      <Card className={classes.root}>
        <CardContent>
          <TextField
            placeholder="Enter your email"
            style={{ width: "100%" }}
            value={email}
            error={!!error}
            helperText={error}
            onChange={onChangeEmail}
            disabled={submitting}
            InputLabelProps={{ shrink: true }}
          />
          {sent && (
            <Box mt={2} color="success.main">
              Magic link sent to {sent}.
            </Box>
          )}
          {magicLinkError && (
            <Box mt={2} color="error.main">
              Something went wrong. ({magicLinkError})
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button
            disabled={!validated || submitting}
            onClick={() => {
              signInWithMagicLink(email);
            }}
          >
            Send Magic Link
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
