import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import useProfile from "../../../hooks/useProfile";
import { useSnackbar } from "notistack";
import { VerificationLevel } from "../../../types/data";
import { AdminApi, UpdateVerificationProfilePayload } from "../../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: 16,
    flexShrink: 0,
  },
  title: {
    fontSize: 14,
  },
  form: {
    "& .MuiTextField-root": {
      margin: 16,
      width: "26ch",
    },
  },
});

const verifications = [
  {
    value: null,
    label: "Not Verified",
  },
  {
    value: VerificationLevel.LEVEL_ONE,
    label: "Verified",
  },
];

export interface ProfileProps {
  uid: string;
}

export default function VerifiedUserCard({ uid }: ProfileProps) {
  const classes = useStyles();

  const profile = useProfile(uid);
  const [submitting, setSubmitting] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const payload = React.useRef<UpdateVerificationProfilePayload>({}).current;

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    payload.displayNameVerified = event.target.value;
  };

  const handleIslandNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    payload.islandNameVerified = event.target.value;
  };

  const handleVerificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    payload.verificationLevel =
      (event.target.value as VerificationLevel) || undefined;
  };

  if (profile === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (profile === null) {
    return (
      <Box mb={2}>
        <Alert severity="warning">Profile doesn't exist</Alert>
      </Box>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          Verified Profile
        </Typography>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            label="Verified Display Name"
            variant="outlined"
            onChange={handleDisplayNameChange}
            defaultValue={profile.displayNameVerified}
          />
          <TextField
            label="Verified Island Name"
            variant="outlined"
            onChange={handleIslandNameChange}
            defaultValue={profile.islandNameVerified}
          />
          <TextField
            select
            label="Select"
            helperText="Verification Level"
            variant="outlined"
            onChange={handleVerificationChange}
            defaultValue={profile.verificationLevel}
          >
            {verifications.map((option) => (
              // @ts-ignore
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="outlined"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            // TODO: do this properly
            AdminApi.updateVerificationProfile(uid, {
              displayNameVerified:
                payload.displayNameVerified || profile.displayNameVerified || profile.displayName,
              islandNameVerified:
                payload.islandNameVerified || profile.islandNameVerified || profile.islandName,
              verificationLevel: payload.verificationLevel,
            })
              .then(() => {
                setSubmitting(false);
                enqueueSnackbar("Successful!", {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          Update
        </Button>
      </CardActions>
    </Card>
  );
}
