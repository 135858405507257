import React from "react";
import Box from "@material-ui/core/Box";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import useReport from "../../hooks/useReports";
import FlightInfoCard from "../../components/FlightInfoCard";
import ReportDetail from "./components/ReportDetail";
import ChatHistoryCard from "../../components/ChatHistoryCard";
import UserInfoCard from "../../components/UserInfoCard";
import { useHistory } from "react-router-dom";
import { useRedirectToSignInIfNotAlready } from "../../hooks/useRedirectToSignInIfNotAlready";

export default function () {
  const user = useRedirectToSignInIfNotAlready();
  const history = useHistory();
  const reportedUsers = useReport();
  const [selectedReportedUid, setSelectedReportedUid] = React.useState<
    string | undefined
  >(undefined);
  const [chatCount, setChatCount] = React.useState<number>(200);

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const uid = url.searchParams.get("uid");
    if (uid) {
      setSelectedReportedUid(uid);
    }

    const chatCount = url.searchParams.get("chatCount");
    if (chatCount) {
      const number = Number(chatCount);
      setChatCount(Number.isNaN(number) ? 200 : number);
    }
  }, []);

  React.useEffect(() => {
    history.push({
      search: `?uid=${selectedReportedUid}&chatCount=${chatCount}`,
    });
  }, [chatCount, history, selectedReportedUid]);

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (reportedUsers === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" height="100vh">
      <Box
        width={120}
        style={{
          backgroundColor: "white",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <List>
          {Object.keys(reportedUsers).map((reportedUid, idx) => (
            <ListItem
              button
              key={reportedUid}
              selected={selectedReportedUid === reportedUid}
              onClick={() => {
                setSelectedReportedUid(reportedUid);
              }}
            >
              <ListItemText
                primary={`#${idx + 1}`}
                style={{ textAlign: "center" }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        p={4}
        style={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        {!selectedReportedUid && <Box>Please select one from the left.</Box>}
        {selectedReportedUid &&
          !["undefined", "null"].includes(selectedReportedUid) && [
            <ReportDetail
              reportedUid={selectedReportedUid}
              reports={reportedUsers[selectedReportedUid]}
              key={"report-detail" + selectedReportedUid}
            />,
            <UserInfoCard
              uid={selectedReportedUid}
              key={"user-info" + selectedReportedUid}
            />,
            <FlightInfoCard
              flightCreatorId={selectedReportedUid}
              key={"flight-info" + selectedReportedUid}
            />,
            <ChatHistoryCard
              flightCreatorId={selectedReportedUid}
              count={chatCount}
              key={"chat-history" + selectedReportedUid}
            />,
          ]}
      </Box>
    </Box>
  );
}
