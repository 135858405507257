import { AdminApi } from "./../api";
import React from "react";
import { UserApi } from "../api";

export enum SearchType {
  EMAIL,
  UID,
  DISPLAY_NAME,
}

export function useUidSearch() {
  const [uid, setUid] = React.useState<string | null | undefined>(undefined);

  const search = React.useCallback(
    (searchType: SearchType, searchPhrase: string) => {
      if (searchType === SearchType.UID) {
        setUid(searchPhrase);
        return;
      }

      if (searchType === SearchType.DISPLAY_NAME) {
        UserApi.getIdByDisplayName(searchPhrase)
          .then((ids) => {
            if (ids.length > 0) {
              setUid(ids[0]);
              return;
            }

            setUid(null);
            return;
          })
          .catch((error) => {
            setUid(null);
          });
        return;
      }

      if (searchType === SearchType.EMAIL) {
        AdminApi.getUidByEmail(searchPhrase)
          .then((id) => {
            if (id) {
              setUid(id);
              return;
            }
            setUid(null);
          })
          .catch((error) => {
            setUid(null);
          });
      }
    },
    []
  );

  return {
    uid,
    search,
  };
}
