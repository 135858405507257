import React from "react";
import firebase from "../shared/firebase";
import { User } from "../types/data";

const ADMIN = [
  // dev
  "cBvZbB5Vm6axleeEnm2ofrcYDxz2",
  "nXFyQa73vlPn9ZqxPrqIv1TXX3t1",
  "kmGe3Q67vNcbAl7k0qBbXnq56rp2",
  "kJ48rzeGdFQs3BtLxQxygqpg8zx2",
  // prod
  "tOAzyCEDmqNOAY0v7jMQdECWUh82",
  "CTdW4CCQxEfvhqzCpniy0gg08J13",
  "EjO5EWYbNcN8rZXazJUkg5o32ZB3",
  "Qq9KlMCUDkZCnh3ihRASanYEf043",
];

export default function useUser() {
  const [user, setUser] = React.useState<User>(undefined);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user && user.emailVerified && ADMIN.includes(user.uid)) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return user;
}
