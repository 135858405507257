import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Row from "../../../components/Row";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import format from "date-fns/format";
import { useSnackbar } from "notistack";
import { AdminApi } from "../../../api";
import Alert from "@material-ui/lab/Alert";
import { Report as ReportType } from "../../../types/data";
import NameLabel from "../../../components/NameLabel";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "80%",
    marginBottom: 16,
    flexShrink: 0,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export interface ReportDetailProps {
  reportedUid: string;
  reports?: ReportType[];
}

export default function ReportDetail({
  reportedUid,
  reports,
}: ReportDetailProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!reports) {
    return (
      <Box mb={2}>
        <Alert severity="warning">
          Report doesn't exist or has been dealt with
        </Alert>
      </Box>
    );
  }
  const lastReportedAt = reports
    ? new Date(
        reports
          .map(({ createdAt }) => createdAt)
          .sort()
          .reverse()[0]
      )
    : new Date();

  const handleConfirm = () => {
    setSubmitting(true);
    AdminApi.confirmReport(reportedUid)
      .then(() => {
        handleClose();
        setSubmitting(false);
        enqueueSnackbar("Successful!", {
          variant: "success",
        });
      })
      .catch((error: Error) => {
        handleClose();
        setSubmitting(false);
        enqueueSnackbar(`Something went wrong. (${error.message})`, {
          variant: "error",
        });
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          Report Details Card
        </Typography>
        <Row title="Count" content={reports.length} />
        <Row
          title="Last Reported At"
          content={`${format(
            new Date(lastReportedAt),
            "yyyy-MM-dd hh:mm:ss"
          )} (${formatDistanceToNowStrict(lastReportedAt)})`}
        />

        <Box
          p={2}
          style={{
            overflowY: "scroll",
            height: 200,
            borderWidth: 1,
            borderColor: "#eeeeee",
            borderStyle: "solid",
          }}
        >
          {reports.map((item, idx) => (
            <Report key={idx} report={item} />
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Button size="small" variant="contained" onClick={handleClickOpen}>
          End Flight and Disable Account
        </Button>
        <Button
          size="small"
          onClick={() => {
            setSubmitting(true);
            AdminApi.dismissReport(reportedUid)
              .then(() => {
                handleClose();
                setSubmitting(false);
                enqueueSnackbar("Successful!", {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                handleClose();
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
          disabled={submitting}
        >
          Remove Reports
        </Button>
      </CardActions>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"End Flight and Disable Account?"}
        </DialogTitle>
        <DialogContent>
          <Box color="text.primary">
            By confirming, this account will be disabled (user won't be able to
            sign in again) and flight will be closed. This will also remove the
            report history.
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

function Report({ report }: { report: ReportType }) {
  const { uid, reason, createdAt } = report;
  return (
    <Box display="flex" flexDirection="column" mb={1}>
      <Box
        component={Link}
        // @ts-ignore
        target="_blank"
        to={`/users?uid=${uid}`}
        style={{ opacity: 0.8 }}
        color="text.primary"
      >
        {<NameLabel uid={uid} />} (
        {format(new Date(createdAt), "yyyy-MM-dd hh:mm:ss")})
      </Box>
      <Box fontSize="1.1em">{reason || "No reason provided."}</Box>
    </Box>
  );
}
