import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";

export const isDev =
  window.location.hostname === "localhost" ||
  window.location.hostname === "turnip-exchange-x.web.app";

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyCsHdVFAU0Z8ngYbcHlPgPjJBOMSsBAt-I",
      authDomain: "turnip-exchange.firebaseapp.com",
      databaseURL: "https://turnip-exchange.firebaseio.com",
      projectId: "turnip-exchange",
      storageBucket: "turnip-exchange.appspot.com",
      messagingSenderId: "136167007178",
      appId: "1:136167007178:web:96b39278391762e81a6533",
      measurementId: "G-WPZP3H336R",
    }
  : {
      apiKey: "AIzaSyDTk-vHXOQY7iNRL_09sroEPGewTLkq8ZU",
      authDomain: "turnips-exchange.firebaseapp.com",
      databaseURL: "https://turnips-exchange.firebaseio.com",
      projectId: "turnips-exchange",
      storageBucket: "turnips-exchange.appspot.com",
      messagingSenderId: "173976423041",
      appId: "1:173976423041:web:2c020cb58dde02ff871b80",
      measurementId: "G-0212XFG0MC",
    };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

if (window.location.hostname === "localhost") {
  window.firebase = firebase;
}

export default firebase;
