import React from "react";

import firebase from "../shared/firebase";
import { Region, Profile, PatreonTier } from "../types/data";

export default function useProfile(uid: string | undefined) {
  const [data, setData] = React.useState<Profile | undefined>(undefined);

  React.useEffect(() => {
    if (!uid) return;

    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot((snapshot) => {
        setData({
          id: snapshot.get("id") || undefined,
          displayName: snapshot.get("displayName") || undefined,
          displayNameVerified: snapshot.get("displayNameVerified") || undefined,
          profilePhoto: snapshot.get("profilePhoto") || undefined,
          islandName: snapshot.get("islandName") || undefined,
          islandNameVerified: snapshot.get("islandNameVerified") || undefined,
          nsId: snapshot.get("nsId") || undefined,
          region: Object.values(Region).includes(snapshot.get("region"))
            ? (snapshot.get("region") as Region)
            : undefined,
          message: snapshot.get("message") || undefined,
          patreon: Object.values(PatreonTier).includes(snapshot.get("patreon"))
            ? (snapshot.get("patreon") as PatreonTier)
            : undefined,
          verificationLevel: snapshot.get("verificationLevel") || undefined,
        });
      });

    return () => {
      unsubscribe();
    };
  }, [uid, setData]);

  return data;
}
