export function isDate(d: any) {
  return d instanceof Date && !isNaN(d.valueOf());
}

export function createSafeDate(a: any) {
  const d = new Date(a);

  if (isDate(d)) {
    return d;
  }

  return new Date();
}
