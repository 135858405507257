import firebase from "firebase";

export enum PatreonTier {
  SUPPORTER = "SUPPORTER", // $3
  RESIDENT = "RESIDENT",
  RESIDENT_PLUS = "RESIDENT_PLUS",
  CITIZEN = "CITIZEN",
  COUNCILOR = "COUNCILOR",
  INVESTOR = "INVESTOR",
  INVESTOR_PLUS = "INVESTOR_PLUS", // $100
}

export enum Region {
  JAPAN = "Japan",
  THE_AMERICAS = "The Americas",
  EUROPE = "Europe",
  AUSTRALIA_NEW_ZEALAND = "Australia/New Zealand",
  HONG_KONG_TAIWAN_SOUTH_KOREA = "Hong Kong/Taiwan/South Korea",
}

export enum TurnipsFlightType {
  SELLING = "SELLING", // High price for selling
  BUYING = "BUYING", // Daisy Mae is on island
}

export enum SpecialCharacter {
  CELESTE = "CELESTE",
  CJ = "CJ",
  DAISY_MAE = "DAISY_MAE",
  FLICK = "FLICK",
  KICKS = "KICKS",
  LEIF = "LEIF",
  REDD = "REDD",
  SAHARAH = "SAHARAH",
  TIMMY_AND_TOMMY = "TIMMY_AND_TOMMY",
}

export enum VerificationLevel {
  LEVEL_ONE = "1",
}

export interface Profile {
  // meta
  id?: string; // Custom ID

  // basic
  displayName?: string;
  islandName?: string;
  profilePhoto?: string;
  nsId?: string;
  region?: Region;
  message?: string;

  // verified
  displayNameVerified?: string;
  islandNameVerified?: string;
  verificationLevel?: VerificationLevel;

  // patreon
  patreon?: PatreonTier;
}

export interface Chat {
  id: string;
  uid: string;
  content: string;
  createdAt: number;
}

export enum TerminalType {
  TURNIPS = "TURNIPS",
  SPECIAL_VISITORS = "SPECIAL_VISITORS",
  GENERAL = "GENERAL",
}

export interface Flight {
  // flight metadata
  status: "open" | "closed";
  terminal: TerminalType;

  // flight details
  maxAllowed: number; // <= 7
  hostId: string; // host uid
  region: Region;
  planToCloseAt: string; // new Date().toUTCString()
  message?: string; // message from the host

  // turnips related
  price?: number;
  turnipsFlightType?: TurnipsFlightType;

  // special character
  specialCharacter?: SpecialCharacter;

  // queue related
  queueLocked: boolean;
  hostStatus: string; // "online" | "offline"

  lastChangedAt: number;
}

export interface FlightPrivate {
  code: string;
}

export type User = firebase.User | null | undefined;

export interface Report {
  uid: string;
  reason?: string;
  createdAt: Date;
}
