import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, Button } from "@material-ui/core";
import Row from "./Row";
import useProfile from "../hooks/useProfile";
import NameLabel from "./NameLabel";
import { isDev } from "../shared/firebase";

const useStyles = makeStyles({
  root: {
    width: "80%",
    marginBottom: 16,
    flexShrink: 0,
  },
  title: {
    fontSize: 14,
  },
});

export interface UserInfoCardProps {
  uid: string;
}

export default function UserInfoCard({ uid }: UserInfoCardProps) {
  const classes = useStyles();

  const profile = useProfile(uid);

  if (profile === undefined) {
    return <CircularProgress />;
  }

  if (profile === null) {
    return <Alert severity="error">User doesn't exist</Alert>;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          User Info Card
        </Typography>
        <Row title="Display Name" content={<NameLabel uid={uid} />} />
        <Row
          title="Island Name"
          content={profile.islandNameVerified || profile.islandName}
        />
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            window.open(
              `${
                isDev
                  ? "https://turnip-exchange.web.app"
                  : "https://turnips.exchange"
              }/flight/${profile.id}`,
              "_blank"
            );
          }}
        >
          Go to flight page
        </Button>
      </CardContent>
    </Card>
  );
}
