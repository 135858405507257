import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { useFlight } from "../hooks/useFlight";
import { CircularProgress } from "@material-ui/core";
import { TerminalType } from "../types/data";
import Row from "./Row";
import format from "date-fns/format";
import { AdminApi } from "../api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    width: "80%",
    marginBottom: 16,
    flexShrink: 0,
  },
  title: {
    fontSize: 14,
  },
});

export interface FlightInfoCardProps {
  flightCreatorId: string;
}

export default function FlightInfoCard({
  flightCreatorId,
}: FlightInfoCardProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = React.useState(false);

  const flight = useFlight(flightCreatorId);

  if (flight === undefined) {
    return <CircularProgress />;
  }

  if (flight === null) {
    return <Alert severity="error">Flight doesn't exist</Alert>;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          Flight Info Card
        </Typography>
        <Row title="Status" content={flight.status} />
        <Row title="Queue Locked" content={String(flight.queueLocked)} />
        <Row title="Terminal" content={flight.terminal} />
        {flight.terminal === TerminalType.TURNIPS && [
          <Row title="Type" content={flight.turnipsFlightType} key="type" />,
          <Row title="Price" content={flight.price} key="price" />,
        ]}
        {flight.terminal === TerminalType.SPECIAL_VISITORS && [
          <Row
            title="Special Visitor"
            content={flight.specialCharacter}
            key="special-visitor"
          />,
        ]}
        <Row title="Max Allowed" content={flight.maxAllowed} />
        <Row
          title="End Time"
          content={
            flight.planToCloseAt === "Invalid Date"
              ? "[Unkown]"
              : format(new Date(flight.planToCloseAt), "yyyy-MM-dd hh:mm:ss")
          }
        />
        <Row title="Message" content={flight.message} />
        <Row
          title="Last Changed At"
          content={format(
            new Date(flight.lastChangedAt),
            "yyyy-MM-dd hh:mm:ss"
          )}
        />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            AdminApi.endFlight(flightCreatorId)
              .then(() => {
                setSubmitting(false);
                enqueueSnackbar("Successful!", {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          End Flight
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            AdminApi.getFlightPrivateCode(flightCreatorId)
              .then((code) => {
                setSubmitting(false);
                enqueueSnackbar(`Dodo Code: ${code}`, {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          Get Code
        </Button>
      </CardActions>
    </Card>
  );
}
