import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CircularProgress, CardActions, Button, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Row from "../../../components/Row";
import Typography from "@material-ui/core/Typography";
import useProfile from "../../../hooks/useProfile";
import { AdminApi } from "../../../api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: 16,
    flexShrink: 0,
  },
  title: {
    fontSize: 14,
  },
});

export interface ProfileProps {
  uid: string;
}

export default function BasicProfileCard({ uid }: ProfileProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const profile = useProfile(uid);

  if (!profile) {
    return <CircularProgress />;
  }

  const handleConfirm = () => {
    setSubmitting(true);
    AdminApi.disableUser(uid)
      .then(() => {
        handleClose();
        setSubmitting(false);
        enqueueSnackbar("Successful!", {
          variant: "success",
        });
      })
      .catch((error: Error) => {
        handleClose();
        setSubmitting(false);
        enqueueSnackbar(`Something went wrong. (${error.message})`, {
          variant: "error",
        });
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          Profile Info
        </Typography>
        <Row title="Region" content={profile.region} />
        <Row title="Display Name" content={profile.displayName} />
        <Row title="Island Name" content={profile.islandName} />
        <Row
          title="Verified Display Name"
          content={profile.displayNameVerified}
        />
        <Row
          title="Verified Island Name"
          content={profile.islandNameVerified}
        />
        <Row
          title="Verified?"
          content={profile.verificationLevel === undefined ? "no" : "yes"}
        />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={handleClickOpen}
        >
          Disable User
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            AdminApi.enableUser(uid)
              .then(() => {
                setSubmitting(false);
                enqueueSnackbar("Successful!", {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          Enable User
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            AdminApi.isUserDisabled(uid)
              .then(({ data }) => {
                setSubmitting(false);
                enqueueSnackbar(data ? "Disabled" : "Not disabled", {
                  variant: data ? "error" : "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          Is Disabled?
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            AdminApi.getEmailByUid(uid)
              .then((email) => {
                setSubmitting(false);
                enqueueSnackbar(`Email: ${email}`, {
                  variant: "success",
                });
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(`Something went wrong. (${error.message})`, {
                  variant: "error",
                });
              });
          }}
        >
          Get Email
        </Button>
      </CardActions>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disable Account?"}</DialogTitle>
        <DialogContent>
          <Box color="text.primary">
            By confirming, this account will be disabled (user won't be able to
            sign in again).
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
