import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";

export default function Row({
  title,
  content = "[Unknown]",
}: {
  title: string;
  content?: any;
}) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      fontSize={16}
      color="text.primary"
      width="100%"
    >
      <Box
        mr={2}
        fontSize={14}
        fontWeight="fontWeightMedium"
        width={90}
        flexShrink={0}
      >
        {title}
      </Box>
      <CopyToClipboard
        text={content}
        onCopy={() => {
          enqueueSnackbar("Copied!", {
            variant: "success",
          });
        }}
      >
        <Box fontWeight="fontWeightBold">{content}</Box>
      </CopyToClipboard>
    </Box>
  );
}
