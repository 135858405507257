import React from "react";
import Box from "@material-ui/core/Box";
import useProfile from "../hooks/useProfile";

export default function NameLabel({
  uid,
  highlight = false,
}: {
  uid: string;
  highlight?: boolean;
}) {
  const profile = useProfile(uid);

  if (!profile) {
    return <Box display="inline-block">Loading</Box>;
  }

  const label = [
    profile.displayNameVerified || profile.displayName || "[Anonymous]",
    profile.verificationLevel !== undefined ? "Verified" : undefined,
    profile.patreon,
  ]
    .filter((i) => !!i)
    .join(" - ");
  return (
    <Box
      display="inline-block"
      fontWeight={highlight ? "fontWeightBold" : undefined}
    >
      {label}
    </Box>
  );
}
