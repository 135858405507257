import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { useChatHistory } from "../hooks/useChatHistory";
import Box from "@material-ui/core/Box";
import NameLabel from "./NameLabel";
import { Link } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

const useStyles = makeStyles({
  root: {
    width: "80%",
    height: 500,
    marginBottom: 16,
    flexShrink: 0,
  },
  title: {
    fontSize: 14,
  },
});

export interface ChatHistoryCardProps {
  flightCreatorId: string;
  count?: number;
}

function shouldHighlightContent(s: string) {
  return ["fake", "scam", "kick", "connection", "report", "*"].reduce(
    (acc, cur) => s.includes(cur) || acc,
    false
  );
}

function Chat({
  flightCreatorId,
  chat,
}: {
  flightCreatorId: string;
  chat: { uid: string; content: string; createdAt: number };
}) {
  const { uid, content, createdAt } = chat;
  return (
    <Box display="flex" flexDirection="column" mb={1}>
      <Box
        component={Link}
        // @ts-ignore
        target="_blank"
        to={`/users?uid=${uid}`}
        style={{ opacity: 0.8 }}
        color="text.primary"
      >
        {<NameLabel uid={uid} highlight={uid === flightCreatorId} />} (
        {format(new Date(createdAt), "yyyy-MM-dd hh:mm:ss")})
      </Box>
      <Box
        fontSize="1.1em"
        fontWeight={
          shouldHighlightContent(content) ? "fontWeightBold" : undefined
        }
      >
        {content}
      </Box>
    </Box>
  );
}

export default function ChatHistoryCard({
  flightCreatorId,
  count,
}: ChatHistoryCardProps) {
  const classes = useStyles();

  const chats = useChatHistory(flightCreatorId, count);

  return (
    <Card className={classes.root}>
      <CardContent
        style={{
          height: "100%",
        }}
      >
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          Chat History Card
        </Typography>

        <Virtuoso
          style={{ width: "100%", height: "400px" }}
          totalCount={chats.length}
          item={(index) => {
            const chat = chats[index];
            return (
              <Chat
                key={chat.id}
                chat={chat}
                flightCreatorId={flightCreatorId}
              />
            );
          }}
          footer={() => (
            <div style={{ padding: "1rem", textAlign: "center" }}>
              -- end reached --
            </div>
          )}
        />
      </CardContent>
      <CardActions>
        <Button size="small">End Flight</Button>
      </CardActions>
    </Card>
  );
}
